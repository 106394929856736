<template>
  <!-- 页脚开始 -->
  <footer class="text999 f12">
    <div class="footer_top textc">
      <span class="pointer clauses"
            @click="showLink">{{
        $t("footer.friendlyLink")
      }}</span>
      <span class="pl15 pr15">|</span>
      <span class="pointer clauses"
            @click="showAddr">{{
        $t("footer.aboutUs")
      }}</span>
    </div>
    <!-- 友情链接 -->
    <div style="background: #f5f5f5"
         class="bordert footer_mid bbox pt5 pb5"
         v-if="linkShow">
      <a href="http://arkray.cn/"
         target="_blank"
         class="text999 dblock">{{
        $t("footer.link1")
      }}</a>
      <a href="https://www.ars.arkray.co.jp/Login.aspx"
         target="_blank"
         class="text999 dblock">{{ $t("footer.link2") }}</a>
      <a href="#/advertising"
         target="_blank"
         class="pointer clauses text999 dblock">{{ $t("footer.privacyPolicy") }}</a>
      <a href="#/useClauses"
         target="_blank"
         class="pointer clauses text999 dblock">{{ $t("footer.UseClauses") }}</a>
      <a target="_blank"
         class="pointer clauses text999 dblock">{{ $t("footer.new") }}</a>
    </div>
    <!-- 地址电话 -->
    <div style="background: #f5f5f5"
         class="bordert footer_mid bbox pt5 pb5 text999 plr13"
         v-if="addShow">
      <div>{{ $t("footer.comName") }}</div>
      <div>{{ $t("footer.phone") }}400-8203867/021-5081-2554</div>
      <div>{{ $t("footer.addr") }}</div>
    </div>
    <div class="footer_bottom flex align-center justify-center">
      <span class="pr20">©️copyright2020-2021</span>
      <img src="../assets/images/beian.png"
           alt=""
           width="16"
           height="16" />
      <a target="_blank"
         href="https://beian.miit.gov.cn"
         rel="nofollow"
         class="text999 pl10">沪ICP备19013058号-2</a>
    </div>
  </footer>
  <!-- 页脚结束 -->
</template>

<script>
export default {
  data () {
    return {
      linkShow: false,
      addShow: false,
    };
  },
  methods: {
    showLink () {
      this.linkShow = !this.linkShow;
      this.addShow = false;
    },
    showAddr () {
      this.addShow = !this.addShow;
      this.linkShow = false;
    },
    toBannerDetail () {
      var sendData = {};
      var that = this;
      this.$http.post("notice/queryTopBannber", sendData).then(function (res) {
        if (200 == res.data.code) {
          that.$router.push({
            path: "/noticeDetail",
            query: {
              title: res.data.data.title,
              noticeCode: res.data.data.noticeCode,
              examCode: res.data.data.examCode,
            },
          })
        }
      });
    }
  },
};
</script>

<style scoped>
footer {
  background-color: #fff;
  height: 70px;
  text-align: center;
}
footer > div {
  line-height: 30px;
}
.footer_top {
  background: #dcdcdc;
  height: 30px;
  color: #666;
}
.footer_top .clauses:hover {
  color: #1f8bd0;
}
.footer_mid > div,
a {
  line-height: 24px;
}
</style>